import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { UsersEngagements } from "../../types/db";
import { RootState } from "../../store";

const initialState = {} as UsersEngagements;

const userEngagementSlice = createSlice({
  name: "userEngagement",
  initialState,
  reducers: {
    setUserEngagement(state, action: PayloadAction<UsersEngagements>) {
      state = action.payload;
      return state;
    },
  },
});

export const selectUserEngagement = (state: RootState) => state.userEngagement;
export default userEngagementSlice.reducer;
export const { setUserEngagement } = userEngagementSlice.actions;
