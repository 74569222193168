import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ActionState } from "../../types/state";

const initialState = {} as ActionState;

const actionSlice = createSlice({
  name: "action",
  initialState,
  reducers: {
    setAction(state, action: PayloadAction<ActionState>) {
      state = action.payload;
      return state;
    },
  },
});

export default actionSlice.reducer;
export const { setAction } = actionSlice.actions;
